import React from "react";

export const TdE = ({ title, onClick, Class }) => (
    <td onClick={onClick} className={`print-border px-1 py-1 text-center cursor-pointer ${Class}`}>
        {title}
    </td>
);

export const Td = ({ title, onClick }) => (
    <td onClick={onClick} className="print-border  text-center cursor-pointer">
        {title}
    </td>
);
export const Mapper = ({ customInfo, onClick }) =>
    customInfo ? (
        <div onClick={onClick} className="flex flex-row mt-10 flex-wrap">
            {Object.keys(customInfo).map((key, index) => {
                const items = customInfo[key];
                return (
                    <div key={index} className="rounded-md m-2 p-5 shadow flex-grow">
                        {Object.keys(items).map((key, index) => (
                            <div key={index} className="flex flex-row justify-between  text-left ">
                                <p className=" pl-2 flex-1 text-1">{items[key]} : </p>
                                <p className="text-2">{key}</p>
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    ) : (
        <></>
    );
