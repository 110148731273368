import React, { useState } from "react";
import Tabel from "../Table/Tabel";
import ExponanitalCard from "./Components/ExponanitalCard";

const ExponanitalView = () => {
    const [data, setData] = useState(null);
    return (
        <div className="rapper" onClick={() => {}}>
            <ExponanitalCard setData={setData} />
            <Tabel data={data} />
        </div>
    );
};

export default ExponanitalView;
