import React from "react";
import { Link, Route } from "react-router-dom";
import "./menu.css";
import MenuProvider from "morabaa-nav";
import ObsView from "./Obs/ObsViiew";
import ExponanitalView from "./Exponanital/ExponanitalView";
import MainSvgs from "./Utils/Svgs";
import NewsView from "./News/NewsView";
import TreadsView from "./Treads/TreadsView";
import ReadFile from "./Exponanital/Components/ReadFile";

// const subRouteBuilder = ({ subRoute }) => {
//     return (
//         <Link style={{ textDecoration: "none" }} to={subRoute.route} className={`sub-route-label ${subRoute.isSelected ? "is-selected" : ""}`}>
//             <p className="font-bold text-center">{subRoute.label}</p>
//         </Link>
//     );
// };

const resolver = (size) => {
    if (size < 400) return "bar";
    else if (size < 720) return "rail";
    else return "railExtended";
};

const IconBuilder = ({ mainRoute }) => {
    return (
        <div className={` ${mainRoute.isSelected ? "is-selected" : ""}`}>
            {mainRoute.icon}
            {/* <p className="font-bold text-center" style={{ maxWidth: 50, maxHeight: 20, overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }}>
                {mainRoute.label}
            </p> */}
        </div>
    );
};

const Root = ({ children }) => {
    console.debug("Root RD");
    return <MenuProvider routes={routes} iconBuilder={IconBuilder} />;
};

export default React.memo(Root);

const routes = [
    {
        label: "Obs",
        icon: MainSvgs.Obs,
        route: "/",
        element: <ObsView />,
    },
    {
        label: "Exponanital",
        icon: MainSvgs.Exponanital,
        route: "/obs",
        element: <ExponanitalView />,
    },
    {
        label: "New",
        icon: MainSvgs.Exponanital,
        route: "/news",
        element: <NewsView />,
    },
    {
        label: "reader",
        icon: MainSvgs.Obs,
        route: "/reader",
        element: <ReadFile />,
    },
];
