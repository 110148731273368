import React, { useState } from "react";
import Round from "../../Components/Round";
import { Mapper, Td, TdE } from "./TableItem";
import CheckBox from "../../Components/CheckBox";

const TreadCard = ({ fileHolder, onClick }) => {
    const [selected, setSelected] = useState(-1);
    const [showOptions, setShowOptions] = useState(localStorage.getItem("showOptions") !== "0");
    const [showSections, setShowSections] = useState(localStorage.getItem("showSections") !== "0");

    const [_index, set_index] = useState(localStorage.getItem("_index") !== "0");
    const [_endLot, set_endLot] = useState(localStorage.getItem("_endLot") !== "0");
    const [_profit, set_profit] = useState(localStorage.getItem("_profit") !== "0");
    const [_startProfit, set_startProfit] = useState(localStorage.getItem("_startProfit") !== "0");
    const [_disposed, set_disposed] = useState(localStorage.getItem("_disposed") !== "0");
    const [_disProfit, set_disProfit] = useState(localStorage.getItem("_disProfit") !== "0");
    const [_risk, set_risk] = useState(localStorage.getItem("_risk") !== "0");
    const [_lastTread, set_lastTread] = useState(localStorage.getItem("_lastTread") !== "0");
    const [_otherLot, set_otherLot] = useState(localStorage.getItem("_otherLot") !== "0");
    const [_difrenc, set_difrenc] = useState(localStorage.getItem("_difrenc") !== "0");
    const [_buyPoints, set_buyPoints] = useState(localStorage.getItem("_buyPoints") !== "0");
    const [_salePoints, set_salePoints] = useState(localStorage.getItem("_salePoints") !== "0");
    const [_startEqity, set_startEqity] = useState(localStorage.getItem("_startEqity") !== "0");
    const [_lastLotStartedTime, set_lastLotStartedTime] = useState(localStorage.getItem("_lastLotStartedTime") !== "0");
    const [_dispozProfit, set_dispozProfit] = useState(localStorage.getItem("_dispozProfit") !== "0");
    const [_dispozLose, set_dispozLose] = useState(localStorage.getItem("_dispozLose") !== "0");
    const [_Time, set_Time] = useState(localStorage.getItem("_Time") !== "0");
    const [_startTime, set_startTime] = useState(localStorage.getItem("_startTime") !== "0");
    const [_endTime, set_endTime] = useState(localStorage.getItem("_endTime") !== "0");
    const [_buyLot, set_buyLot] = useState(localStorage.getItem("_buyLot") !== "0");
    const [_saleLot, set_saleLot] = useState(localStorage.getItem("_saleLot") !== "0");
    const [_take, set_take] = useState(localStorage.getItem("_take") !== "0");
    const [_treadsGroup, set_treadsGroup] = useState(localStorage.getItem("_treadsGroup") !== "0");

    return fileHolder ? (
        <>
            <div className="my-3 flex flex-row">
                <CheckBox checked={showSections} setChecked={setShowSections} storge="showSections" title="Details" />
                <CheckBox checked={showOptions} setChecked={setShowOptions} storge="showOptions" title="Options" />
            </div>
            {showSections && <Mapper customInfo={fileHolder.customInfo} />}

            {showOptions && (
                <div className="flex flex-row flex-wrap card-3 p-2 rounded-lg mb-6 shadow">
                    <CheckBox checked={_index} setChecked={set_index} storge="_index" title="Index" />
                    <CheckBox checked={_endLot} setChecked={set_endLot} storge="_endLot" title="EndLot" />
                    <CheckBox checked={_profit} setChecked={set_profit} storge="_profit" title="Profit" />
                    <CheckBox checked={_startProfit} setChecked={set_startProfit} storge="_startProfit" title="StartedProfit" />
                    <CheckBox checked={_take} setChecked={set_take} storge="_take" title="MaxProfit" />
                    <CheckBox checked={_disposed} setChecked={set_disposed} storge="_disposed" title="Safe" />
                    <CheckBox checked={_disProfit} setChecked={set_disProfit} storge="_disProfit" title="SafeGain" />
                    <CheckBox checked={_risk} setChecked={set_risk} storge="_risk" title="MaxRisk" />
                    <CheckBox checked={_lastTread} setChecked={set_lastTread} storge="_lastTread" title="LastTread" />
                    <CheckBox checked={_otherLot} setChecked={set_otherLot} storge="_otherLot" title="OtherLot" />
                    <CheckBox checked={_difrenc} setChecked={set_difrenc} storge="_difrenc" title="LotDifference" />
                    <CheckBox checked={_buyPoints} setChecked={set_buyPoints} storge="_buyPoints" title="BuyPoints" />
                    <CheckBox checked={_salePoints} setChecked={set_salePoints} storge="_salePoints" title="SalePoints" />
                    <CheckBox checked={_startEqity} setChecked={set_startEqity} storge="_startEqity" title="StartEqity" />
                    <CheckBox checked={_lastLotStartedTime} setChecked={set_lastLotStartedTime} storge="_lastLotStartedTime" title="LastLotSartedDate" />
                    <CheckBox checked={_dispozProfit} setChecked={set_dispozProfit} storge="_dispozProfit" title="SafeAreayPoint" />
                    <CheckBox checked={_dispozLose} setChecked={set_dispozLose} storge="_dispozLose" title="Aversion" />
                    <CheckBox checked={_Time} setChecked={set_Time} storge="_Time" title="Time" />
                    <CheckBox checked={_startTime} setChecked={set_startTime} storge="_startTime" title="StartDate" />
                    <CheckBox checked={_endTime} setChecked={set_endTime} storge="_endTime" title="EndDate" />
                    <CheckBox checked={_buyLot} setChecked={set_buyLot} storge="_buyLot" title="BuyLot" />
                    <CheckBox checked={_saleLot} setChecked={set_saleLot} storge="_saleLot" title="SaleLot" />
                    <CheckBox checked={_treadsGroup} setChecked={set_treadsGroup} storge="_treadsGroup" title="TreadsGroup" />
                </div>
            )}

            <table style={{ fontWeight: 500 }} className={` print-container  bg-blue-1 text-1`}>
                <tbody>
                    {/* <Td onClick={() => onClick("EndLot")} title="Index" />
                        <Td onClick={() => onClick("Profit")} title="Profit" />
                    <Td onClick={() => onClick("Lot")} title="Lot" /> */}
                    <tr>
                        <Td onClick={() => onClick("EndLot")} title="Index" />
                        {Object.keys(fileHolder.treads[0]).map((key, i) => (
                            <Td key={i} onClick={() => onClick(key)} title={key} />
                        ))}
                    </tr>
                </tbody>
                <tbody className={`${selected !== -1 ? "table-blur" : ""} `}>
                    {fileHolder.treads?.map((value, i) => (
                        <tr
                            className={`tr-${selected === i ? "active card-1 text-1" : i % 2 > 0 ? "1" : "2"}`}
                            key={i}
                            onClick={() => setSelected(selected === i ? -1 : i)}>
                            <TdE title={i + 1} />
                            {Object.values(value).map((item, i) => (
                                <TdE key={i} title={item} />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    ) : (
        <></>
    );
};

export default TreadCard;
