import "./Css/index.css";
import "./Css/global.css";
import "./Css/loader.css";
import "./Css/theme.css";
// import App from "./App";
import React from "react";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import "./menu.css";
import { P } from "./NodeBuilder/NodeBuilder";
import Root from "./Root";

let theme = localStorage.getItem("theme");
const toggleThemeButton = P({
    className: "toggle-theme-button",
    innerText: theme === "dark" ? "🌙" : "🌞",
    onclick: ({ currentTarget }) => {
        theme = theme === "dark" ? "" : "dark";
        localStorage.setItem("theme", theme);
        document.body.className = theme;
        currentTarget.innerText = theme === "dark" ? "🌙" : "🌞";
    },
});

document.body.append(toggleThemeButton);
document.body.className = theme;

createRoot(document.getElementById("root")).render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
