import React from "react";
// import Languages from "../Utils/Languages";

const TextInput = ({ value, setValue, Key, required, autoFocus, inputRef, type = "text", step }) => (
    <div className="flex flex-row items-center relative">
        <input
            ref={inputRef}
            name={Key}
            type={type}
            value={value}
            required={required}
            autoFocus={autoFocus}
            // placeholder={Languages[Key] || Key}
            placeholder={Key}
            step={type === "number" ? step : ""}
            onChange={({ target }) => setValue(target.value)}
            className="font-bold flex-grow outline-none carbonic carbonic-card border-none px-4 py-2  rounded-xl shadow-lg focus:shadow-cyan"
        />
        <p className="absolute right-3 carbonic-card font-bold text-2">{Key}</p>
    </div>
);

export default TextInput;
// <TextInput value={} setValue={}  Key="" />
