import React, { useState } from "react";
import { TdHeader, Td } from "./TableItem";

const Tabel = ({ data, onColumnClick }) => {
    const [selected, setSelected] = useState(-1);
    console.log(data);
    return data ? (
        <table style={{ fontWeight: 500 }} className={`print-container bg-blue-1 text-1`}>
            <tbody>
                <tr>
                    <TdHeader onClick={() => onColumnClick("")} title="i" />
                    {data.keys?.map((key, i) => (
                        <TdHeader key={i} onClick={() => onColumnClick("")} title={key} />
                    ))}
                </tr>
            </tbody>
            <tbody className={`${selected !== -1 ? "table-blur" : ""} `}>
                {data.items?.map((item, i) => (
                    <tr
                        className={`tr-${selected === i ? "active card-1 text-1" : i % 2 > 0 ? "1" : "2"}`}
                        key={i}
                        onClick={() => setSelected(selected === i ? -1 : i)}>
                        <Td title={i} />
                        {data.keys?.map((key, _i) => (
                            <Td key={_i} title={item[key]} />
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <></>
    );
};

export default Tabel;
