import React from "react";

const data = {};
const NewsView = () => {
    React.useEffect(() => {
        setTimeout(() => {
            // document.getElementById("news-container").innerHTML = test;
            const container = document.getElementById("news-container");
            const children = document.getElementsByClassName("calendar__row calendar_row calendar__row--new-day" || "calendar__row");
            Object.values(children).forEach((child) => {
                let monthAndDay = child.childNodes[1].childNodes[1].childNodes[1].innerText.split(" ");
                if (monthAndDay[0] !== "Nov") return;
                // let _dayAndMonth = child.querySelector(".date");
                let hourAndMinute = "";
                let _hourAndMinute = child.querySelector(".time");
                if (!_hourAndMinute) {
                    hourAndMinute = child.querySelector(".icon--upnext").innerText;
                } else if (_hourAndMinute.innerText) {
                    hourAndMinute = _hourAndMinute.innerText;
                }
                if (!hourAndMinute) {
                    child.style.opacity = 0.5;
                    return;
                } else {
                    hourAndMinute = hourAndMinute.trim();
                }

                container.append(child);
                let justTime = hourAndMinute.split(":");
                let houre = parseInt(hourAndMinute[0]);
                let minute = justTime[1]?.trim();
                if (!minute) return;
                if (minute.endsWith("pm")) {
                    console.log(justTime);
                    minute = parseInt(minute.split("pm")[0]);
                    houre += 12;
                } else {
                    minute = parseInt(minute.split("am")[0]);
                }

                let event = {
                    month: monthTextToNumber(monthAndDay[0]),
                    day: parseInt(monthAndDay[1]),
                    hour: parseInt(houre) - 1,
                    minute: parseInt(minute),
                };

                if (event.minute == 0) {
                    event.minute = 59;
                    event.hour -= 1;
                } else event.minute -= 1;
                data[event.day] = event;
            });

            let matrex = "";
            for (let i = 0; i < 32; i++) {
                let event = data[i];
                matrex += "\n";
                if (event) {
                    for (let j = 0; j < 24; j++) {
                        if (event.hour === j) {
                            console.log(i, "-", event);
                            matrex += event.minute + ",";
                        } else {
                            matrex += "0,";
                        }
                    }
                } else {
                    for (let j = 0; j < 24; j++) {
                        matrex += "0,";
                    }
                }
            }
            navigator.clipboard.writeText(matrex.trimEnd(","));
            console.log(matrex.trimEnd(","));
        }, 10);
    }, []);
    return <div id="news-container">news</div>;
};

export default NewsView;
const monthTextToNumber = (month) => ({ Dec: 12, Nov: 11, Oct: 10, Sep: 9, Aug: 8, Jul: 7, Jun: 6, May: 5, Apr: 4, Mar: 3, Feb: 2, Jan: 1 }[month]);
