import React from "react";

export const Td = ({ title, onClick, Class }) => (
    <td onClick={onClick} className={`print-border px-1 py-1 text-center cursor-pointer ${Class}`}>
        {title}
    </td>
);

export const TdHeader = ({ title, onClick }) => (
    <td onClick={onClick} className="print-border  text-center cursor-pointer">
        {title}
    </td>
);
