import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import Label from "../../Components/Label";
import Round from "../../Components/Round";
import TextInput from "../../Components/TextInput";
import Formatter from "../../Utils/Formatter";

let total = 0;
let loss = 0;

const Symboles = {
    DJ: {
        LotByBalance: 350,
        PointValuue: 10,
    },
    XAU: {
        LotByBalance: 550,
        PointValuue: 100,
    },
};

const obsSaved = JSON.parse(localStorage.getItem("obsSaved")) || { lot: 0.1, profitToLose: 4, factors: 2, steps: 10, points: 1, symbole: "DJ" };
let init = false;

const ObsCard = ({ setData }) => {
    const [lot, setLot] = useState(obsSaved.lot);
    const [profitToLose, setProftiToLose] = useState(obsSaved.profitToLose);
    const [factors, setFactors] = useState(obsSaved.factors);
    const [steps, setSteps] = useState(obsSaved.steps);
    const [points, setPoints] = useState(obsSaved.points);
    const [symbole, setSymbole] = useState(obsSaved.symbole);

    const calclate = () => {
        let _lot = Round(lot);
        let _points = Round(points * selectedSymbole.PointValuue);
        total = 0;
        loss = 0;
        let profits = [{ Lot: "-", Profit: "-", "Total-P": Round(_points * _lot), "Total-L": "-", Loss: "-", "B-Req": "-" }]; //Traies: "-",
        for (let index = 0; index < steps; index++) {
            profits.push(getResult(_lot, _points));
            _lot = Round(_lot * factors);
            console.log(_lot * factors);
        }
        setData({ keys: Object.keys(profits[0]), items: profits });
    };
    const selectedSymbole = React.useMemo(() => {
        let _prevLot = lot;
        const _symbole = Symboles[symbole];
        while (_prevLot < 1) _prevLot *= 10;
        setLot((1 / _symbole.PointValuue) * _prevLot);
        return _symbole;
    }, [symbole]);

    const getResult = (_lot, _points) => {
        const profit = Round(_lot * _points * profitToLose);
        const lost = Round(_lot * _points);
        const balanceRequired = Round(_lot * selectedSymbole.LotByBalance + loss);
        total = Round(profit - loss);
        loss = Round(loss + lost);
        return {
            Lot: Formatter(_lot),
            Profit: Formatter(profit),
            Loss: Formatter(lost),
            "Total-P": Formatter(Math.round(total)),
            "Total-L": Formatter(Math.round(loss)),
            "B-Req": Formatter(Math.round(balanceRequired)),
            Traies: Formatter(total / (_points * lot)),
        };
    };

    useEffect(() => {
        calclate();
        if (!init) init = true;
        else localStorage.setItem("obsSaved", JSON.stringify({ lot, profitToLose, factors, steps, points, symbole }));
    }, [lot, factors, steps, points, profitToLose]);

    return (
        <div style={{ direction: "ltr" }} className="flex flex-col gap-3 background py-5 rounded-md">
            <p className="text-center carbonic-card-1 rounded-2xl self-center p-1">
                {Object.keys(Symboles).map((key, i) => (
                    <span
                        key={i}
                        className={`mx-2 select-none cursor-pointer px-4 rounded-xl text-white ${symbole === key ? "red-card" : "carbonic-card-1"}`}
                        onClick={() => setSymbole(key)}>
                        {key}
                    </span>
                ))}
            </p>
            <TextInput value={lot} setValue={setLot} Key="Lot" type="number" step={1 / selectedSymbole.PointValuue} />
            <TextInput value={profitToLose} setValue={setProftiToLose} Key="ProftiToLose" type="number" />
            <TextInput value={factors} setValue={setFactors} Key="Factor" type="number" step={0.1} />
            <TextInput value={steps} setValue={setSteps} Key="Steps" type="number" />
            <TextInput value={points} setValue={setPoints} Key="Points" type="number" />
            {/* <Label title="Result" info={profit} />
            <Label title="BalanceRequired" info={balaceRequired} /> */}
            {/* <Button onClick={calclate} color="green" title="Calculate" /> */}
        </div>
    );
};

export default React.memo(ObsCard);
