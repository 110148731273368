import React, { useState } from "react";
import Tabel from "../Table/Tabel";
import ObsCard from "./Components/ObsCard";

const ObsView = () => {
    const [data, setData] = useState(null);
    return (
        <div className="rapper" onClick={() => {}}>
            <ObsCard setData={setData} />
            <Tabel data={data} />
        </div>
    );
};

export default ObsView;
